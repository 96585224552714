.first-nav {
    background: $primary;
    color: $white;
    display: flex;
    align-items: center;
    height: 70px;
    @media (max-width: 768px) {
        background: #ebeef7;
        color: $primary;
    }
    .res_nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        font-weight: 600;
        @media (max-width: 425px) {
            padding: 0px 15px;
        }
        .first_res {
            display: flex;
            align-items: center;
            gap: 15px;
            div {
                display: flex;
                align-items: center;
                gap: 8px;
                i {
                    font-size: 20px;
                }
                p {
                    font-size: 15px;
                }
            }
            @media (max-width: 425px) {
                display: none;
            }
        }
        .second_res {
            display: flex;
            align-items: center;
            @media (max-width: 425px) {
                width: 100%;
                justify-content: space-between;
            }
            #btn_nav {
                i {
                    font-size: 35px;
                    font-weight: 900;
                    display: none;
                    @media (max-width: 768px) {
                        display: block;
                        font-size: 30px;
                    }
                }
                @media (min-width: 768px) {
                    order: 1;
                    margin-left: 15px;
                }
            }
            .mobile-logo {
                display: none;
                width: 55px;
                height: 55px;
                background-repeat: none;
                background-size: cover;
                @media (max-width: 425px) {
                    display: block;
                }
            }
        }
    }
}

.drop-lang {
    display: flex;
    align-items: center;
    gap: 15px;
    a {
        .kh-lang {
            background-image: url("../../../public/image/static/english.svg");
            background-size: cover;
            background-position: center;
            width: 40px;
            height: 40px;
            border-radius: 45px;
            @media (max-width:768px) {
                width: 35px;
                height: 35px;
            }
            @media (max-width: 425px) {
                width: 30px;
                height: 30px;
            }
        }
        .en-lang {
            background-image: url("../../../public/image/static/cambodia.svg");
            background-size: cover;
            background-position: center;
            width: 40px;
            height: 40px;
            border-radius: 45px;
            @media (max-width:768px) {
                width: 35px;
                height: 35px;
            }
            @media (max-width: 425px) {
                width: 30px;
                height: 30px;
            }
        }
    }
    .datatime {
        p {
            margin: 0;
            font-size: 15px;
        }
        @media (max-width: 425px) {
            display: none;
        }
    }
}

.right_menu {
    width: 100%;
    a {
        gap: 7px;
        padding: 5px 25px;
        i {
            font-size: 25px;
        }
        @media (max-width: 1024px) {
            padding: 5px 10px;
        }
    }
}
.sub_menu {
    background-color: $third;
}
.sub_menu .logo img {
    width: 35%;
}
.sub_menu .right_menu a {
    display: flex;
    margin: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    .live {
        width: 50px;
        height: 100%;
        background-image: url("https://cdn-icons.flaticon.com/png/512/3049/premium/3049367.png?token=exp=1651312430~hmac=05eb9955f8437c530c2f88ce1f31128b");
        background-repeat: none;
        background-size: cover;
    }
}
.sub_menu .right_menu a {
    color: $primary;
    li {
        list-style: none;
        font-weight: 700;
        cursor: pointer;
        @media (max-width: 2560px) {
            font-size: 23px;
        }
        @media (max-width: 1440px) {
            font-size: 23px;
        }
        @media (max-width: 1024px) {
            font-size: 19px;
        }
    }
}
.sub_menu .right_menu .a-nav:hover {
    background-color: $secondary;
    color: white;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 47px;
}
.actives {
    background-color: $secondary;
    border-radius: 47px;
    color: white !important;
}
.dropdown_nav {
    position: absolute;
    z-index: 9999999;
    background-color: #3575c1;
    font-weight: 700;
    display: block;
    width: 100%;
    display: none;
    padding: 0 15px;
    .cancel{
        display: flex;
        justify-content: end;
        p{
            font-size: 35px;
            font-weight: 900;
            color: $white;
        }
        .bi-x{
            color: $white;
            font-size: 35px;
        }
    }
    a {
        color: $white;
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 8px;
        i {
            font-size: 25px;
        }
        li {
            font-size: 18px;
        }
        &:hover {
            color: $secondary;
        }
    }
}

.dropdown_nav li {
    padding: 10px 5px;
    list-style: none;
}

@keyframes rotate-vertical {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}

@keyframes blinker {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.live-indicator-block {
    animation: rotate-vertical 5s ease-out infinite alternate;
    .live-indicator {
        background: $red-live;
        color: $white;
        padding: 8px 18px;
        line-height: 1;
        border-radius: 45px;
        text-transform: uppercase;
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
        font-weight: bold;
        width: auto;
        .blink {
            animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
            font-size: 10px;
            margin-right: 5px;
            vertical-align: baseline;
        }
    }
}
