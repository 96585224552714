body {
    background: $white;
    font-family: Poppins, "Noto Sans Khmer", sans-serif;
    padding: 0;
    margin: 0;
}

.checksection {
    li {
        padding-bottom: 10px;
    }
}

.news-box {
    cursor: pointer;
    .img {
        width: 100%;
        height: 200px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.tt {
    color: $primary;
    position: relative;
    text-transform: uppercase;
    line-height: 30px;
    font-size: 2rem;
    text-align: center;
    margin: 40px 0px;
    @media(max-width:425px){
        font-size: 1.7rem;
    }
}

.article_desc {
    h3 {
        font-size: 1.5rem;
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        margin-bottom: 0.5rem;
        color: $primary;
    }
    div {
        p {
            font-weight: 600;
            letter-spacing: 1px;
            span {
                padding: 0px 40px;
            }
        }
    }
    img {
        max-width: 100%;
        margin-bottom: 20px;
    }
}

.howtoplay {
    .row {
        .htp {
            display: flex;
            align-items: center;
            @media (max-width: 768px) {
                padding-top: 15px;
            }
            .htp-title {
                width: 100%;
                height: 100%;
                div {
                    align-items: center;
                    background: #0f5cb6;
                    padding: 15px 25px;
                    display: inline-block;
                    border-radius: 47px;
                    gap: 15px;
                    color: #fff;
                }
                p {
                    color: #333;
                    margin-top: 30px;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 1.6;
                }
            }
        }
    }
}

