.article_desc {
    padding: 30px 0px;
    .date-show {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 10px;
        color: #527bc2 !important;
        p {
            margin: 0;
            padding: 0;
        }
    }
    .latest-news {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;

        a {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-wrap: nowrap;
            text-decoration: none;
            gap: 10px;
            transition: all 3s ease;
            &:hover {
                .box-thumb {
                    .img-rc-news {
                        background-size: 120%;
                        box-shadow: inset 0 0 10px 2px black;
                        transition: all 0.3s ease;
                    }
                }
                .box-title {
                    text-decoration: underline solid #444444 1px;
                }
            }
            h5 {
                text-align: left;
                color: #444444;
            }
            .box-thumb {
                height: 80px;
                display: flex;
                justify-content: flex-start;
                transition: all 3s ease;
                .img-rc-news {
                    width: 150px;
                    height: 110%;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    background-position: center;
                    border-radius: 10px;
                    -webkit-transition: all 0.3s ease;
                    transition: all 0.3s ease;
                }
            }
        }
    }
}
@media (min-width: 1200px) {
    .article_desc {
        padding: 30px 0px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .article_desc {
        padding: 30px 0px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .article_desc {
        padding: 30px 30px;
        .latest-news{
           justify-content: start;
           a{
               justify-content: start;
               display: block;
            .box-thumb{
                width: auto;
                justify-content: start;
            }
            .box-title{
                width: auto;
                margin-top: 12px;
            }
           }
        }
        
        
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .article_desc {
        padding: 10px 10px;
        .latest-news{
            justify-content: start;
            a{
                justify-content: start;
             .box-thumb{
                 width: auto;
             }
            }
         }
    }
}

@media (max-width: 575.98px) {
    .article_desc {
        padding: 10px 10px;
        .latest-news{
            justify-content: start;
            a{
                justify-content: start;
             .box-thumb{
                 width: auto;
             }
            }
         }
    }
}
