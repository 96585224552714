$primary: #0f5cb6;
$secondary: #dbbc58;
$third: #ebeef7;
$white: #fff;
$red-live: #ea2429;

@import url("https://fonts.googleapis.com/css2?family=Battambang:wght@100;300;400;700;900&family=Noto+Sans+Khmer:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~bootstrap/scss/bootstrap.scss";
@import "~owl.carousel/dist/assets/owl.carousel.css";
@import "./client/header.scss";
@import "./client/main.scss";
@import "~aos/dist/aos.css";
@import "./client/home.scss";
@import "./client/product.scss";
@import "./client/article.scss";
@import "./client/aboutus.scss";
@import "./client/footer.scss";

