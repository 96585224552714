.pingpong {
    padding: 0px 13px;
    border-radius: 50%;
    background: radial-gradient(
        circle at 50% 40%,
        #fff,
        #dededf 60%,
        #3c5aa0 100%
    );
    font-size: 35px;
}
.playtime {
    border-collapse: collapse;
    caption-side: bottom;
    margin-top: 20px;
    font-size: 20px;
    thead {
        background: #2c5ab4;
        color: $white;
        text-align: center;
        th {
            font-weight: 500;
            padding: 12px;
        }
    }
    tbody tr:nth-child(even) {
        background-color: #f8f9fa;
    }

    tbody tr:nth-child(odd) {
        background-color: #e9ecef;
    }
    tbody {
        td {
            text-align: center;
            padding: 12px;
        }
    }
}
