.footer {
    line-height: 1.6;
    background: $white;
    .thankyou {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 12px;
        background-color: $primary;
        color: $white;
        div {
            p {
                background-color: #7798d9;
                padding: 15px 25px;
                border-radius: 47px;
                @media (min-width: 768px) {
                    padding: 10px 15px;
                    font-size: 20px;
                }
                @media (max-width: 425px) {
                    padding: 10px 15px;
                    font-size: 18px;
                }
                @media (max-width: 320px) {
                    padding: 10px 15px;
                    font-size: 15px;
                }
            }
        }
    }
    .thankyou-subtitle {
        text-align: center;
        color: $white;
        padding: 12px;
        background-color: #72a1f9;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        @media (max-width: 768px) {
            margin-bottom: 40px;
        }
        @media (max-width: 425px) {
            display: block;
        }
        p {
            font-size: 18px;
        }
        div {
            margin: 15px 0px;
            a {
                border-radius: 10px;
                text-decoration: none;
                color: $white;
                border-radius: 47px;
                border: 2px solid $white;
                padding: 6px 12px;
                background: transparent;
                &:hover {
                    background: $primary;
                }
            }
        }
    }
    .container {
        .row {
            div {
                ul {
                    li {
                        list-style: none;
                        margin: 10px 0px;
                        p {
                            font-size: 18px;
                            font-weight: 500;
                        }
                        img {
                            width: 45%;
                            height: 45%;
                            @media (max-width: 768px) {
                                width: 30%;
                            }
                        }
                        i {
                            color: black;
                        }
                        h3 {
                            color: $primary;
                            font-weight: 700;
                        }
                    }
                    a {
                        text-decoration: none;
                        li {
                            font-size: 18px;
                            color: black;
                            font-weight: 500;
                            text-decoration: underline;
                            &:hover {
                                padding: 0px 5px;
                                color: $primary;
                                transition: 0.1s;
                            }
                        }
                    }
                }
            }
        }
    }
}
.footer-title {
    font-weight: 500;
    color: $primary;
}

.call {
    width: 15%;
    height: 15%;
    @media (max-width: 1024px) {
        width: 20%;
        height: 20%;
    }
    @media (max-width: 768px) {
        width: 13%;
        height: 13%;
    }
    @media (max-width: 425px) {
        width: 10%;
        height: 10%;
    }
}
