.thover {
    .img {
        width: 100%;
        height: 200px;
        background-position: center;
        background-size: 500px;
        background-repeat: no-repeat;
        transition: 0.1s;
    }
    &:hover {
        .img {
            background-size: 470px;
            transition: all 0.2s ease;
            box-shadow: inset 0 0 10px 2px black;
        }
    }
}
.cards {
    font-size: 22px;
    margin-bottom: 10px;
    color: $primary;
    font-weight: 700;
}
