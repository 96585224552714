$t: 6.5s;
$e1: cubic-bezier(0.8, 0.00, 0.1, 1);
$e2: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$er: linear;
$color: hsl(15,30%,30%);
$font: 'Gotham', Gotham;

$slides: 4;
$images:
url(https://scontent.fpnh11-1.fna.fbcdn.net/v/t39.30808-6/289621591_145895474698903_5523282102922797022_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=e3f864&_nc_ohc=fm6x6wUl3IAAX-iQ8ZH&_nc_ht=scontent.fpnh11-1.fna&oh=00_AT_Ah25Nk-Wkd_a-UqRSAkwIFcQhDhjnz4aaaI3gyOtreg&oe=631B06B6)
url(https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/cfc2df73132301.5bff37bf2756b.jpg)
url(https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/7532cd73132301.5bff37bf25d07.jpg)
url(https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/299a9973132301.5bff37bf24987.jpg);

.wrapper-slide {
    &:hover {
        .owl-nav {
            opacity: 1;
        }
    }
    .owl-nav {
        opacity: 0;
        transition: all ease-in-out 0.3s;
        svg {
            width: 13px;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.owl-prev {
    position: absolute;
    top: 35%;
    left: 0;
    transform: translate(10%, -50%);
    background: rgb(0 0 0 / 50%) !important;
    backdrop-filter: saturate(180%) blur(20px);
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.owl-next {
    position: absolute;
    top: 35%;
    right: 0;
    background: rgb(0 0 0 / 50%) !important;
    transform: translate(-10%, -50%);
    backdrop-filter: saturate(180%) blur(20px);
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.owl-dots {
    position: relative;
    text-align: center;
    margin-top: 0px;
    z-index: 2000;
    bottom: none;
    outline: none;
}

.owl-dot {
    width: 10px;
    height: 10px;
    background: #ddd !important;
    margin: 0 6px;
    border-radius: 14px;
    outline: none;
    -webkit-transition: 0.3s linear;
    transition: 0.3s linear;
    &:focus {
        outline: none;
    }
    &:hover {
        width: 30px;
        background: $secondary !important;
    }
    .active {
        width: 30px;
        background: $primary !important;
    }
}

.slide {
    @media(max-width:2560px){
        height: 530px;
    }
    @media(max-width:1024px){
        height: 385px;
    }
    @media(max-width:768px){
        height: 290px;
    }
    @media(max-width:425px){
        height: 125px;
    }
    .bg_slide {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}
.btn-result {
    display: flex;
    justify-content: end;
    align-items: flex-start;
    @media(max-width:425px){
        justify-content: center;
    }
    .linktors1 {
        text-decoration: none;
        font-size: 18px;
        background-color: $primary;
        color: $white;
        padding: 10px 20px;
        border-radius: 25px;
    }
}
.hidescreen {
    position: relative;
    div {
        position: absolute;
        top: 0;
        right: 0;
        background-color: $white;
        width: 120px;
        height: 120px;
        @media(max-width:425px){
            display: none;
        }
    }
    iframe{
        @media(max-width:425px){
            display: none;
        }
    }
}

